@media screen and (max-width: 768px) {
  #mr-pickup-selector {
    flex-direction: column-reverse;
  }
  
  #mr-pickup-list {
    width: 100% !important;
    border-right: none !important;
  }
  
  #mr-leaflet-map {
    width: 100% !important;
    flex: none !important;
    height: 250px !important;
  }
}
